import React from 'react'
import { Routes, useLocation } from 'react-router-dom'
import { PageRoutes } from './constants/routes'

export default function Header() {
    const location = useLocation()

    return (
        <>
            <nav className="">
                <h2 className="sr">Main Navigation</h2>
                <div className="d-flx j-c-sb nav al-i-fe">
                    <div className="nav-bar-mobile">
                        <div className="">
                            <a href="/"><svg viewBox="0 0 139 45" width="140px" height="45px"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="m0 44.276v-0.2858l0.64539-0.2c0.4303-0.1142 0.71534-0.2856 0.85514-0.5142 0.13979-0.2285 0.20975-0.5333 0.20975-0.9143v-14.971c0-0.381-0.07529-0.6858-0.22588-0.9143-0.15058-0.2286-0.44096-0.4094-0.87126-0.5428l-0.61314-0.2286v-0.2857h7.8412c3.2054 0 5.5233 0.5191 6.9539 1.5571 1.4306 1.0381 2.1459 2.4047 2.1459 4.0998 0 1.0858-0.2958 2.081-0.8874 2.9856-0.5917 0.9048-1.565 1.6238-2.9203 2.1571-1.3552 0.5334-3.1839 0.8-5.4857 0.8h-1.0971v5.2571c0 0.7427 0.4195 1.2285 1.2585 1.457l0.96801 0.2571v0.2858h-8.777zm6.5505-7.8284h1.2263c1.0541 0 1.9146-0.1523 2.5815-0.4571 0.6669-0.3048 1.1617-0.8381 1.4844-1.6 0.3226-0.7618 0.484-1.8189 0.484-3.1713 0-1.3714-0.1775-2.438-0.5324-3.1999-0.355-0.7618-0.8766-1.2904-1.565-1.5856-0.68847-0.2953-1.5489-0.4429-2.5816-0.4429h-1.0971v10.457z"
                                    fill="#333" />
                                <path
                                    d="m18.033 44.274v-0.2858l0.4518-0.1142c0.7529-0.1906 1.1294-0.6572 1.1294-1.4v-8.9425c0-0.4191-0.0753-0.7286-0.2259-0.9286s-0.4411-0.3381-0.8712-0.4143l-0.4841-0.1143v-0.2857l5.5179-1.657 0.3227 0.2856 0.2582 1.7143c0.7529-0.5905 1.6026-1.0762 2.5491-1.4572 0.9466-0.3808 1.8823-0.5713 2.8074-0.5713 1.4198 0 2.5115 0.3428 3.2752 1.0285s1.1455 1.7333 1.1455 3.1428v8.2283c0 0.7429 0.4087 1.2095 1.2263 1.3999l0.2904 0.0857v0.2858h-7.6154v-0.2858l0.4195-0.1142c0.7529-0.2096 1.1294-0.6762 1.1294-1.4v-9.114c0-1.219-0.6884-1.8285-2.0651-1.8285-0.9466 0-1.9792 0.419-3.0978 1.2571v9.714c0 0.7429 0.3764 1.2095 1.1293 1.3999l0.2904 0.0857v0.2858h-7.583z"
                                    fill="#333" />
                                <path
                                    d="m44.785 30.103c1.3983 0 2.5922 0.2524 3.5818 0.7571 0.9894 0.5047 1.7478 1.1905 2.2749 2.0571 0.527 0.8667 0.7905 1.8523 0.7905 2.9571 0 0.2094-0.0108 0.4237-0.0323 0.6428-0.0214 0.219-0.0645 0.4143-0.1291 0.5857h-9.6159c0.0215 2.057 0.4357 3.5379 1.2423 4.4427 0.8068 0.9047 2.006 1.3571 3.5979 1.3571 1.0757 0 1.947-0.1525 2.6138-0.4571 0.6669-0.3048 1.2908-0.7524 1.8715-1.3429l0.3228 0.2571c-0.667 1.0477-1.5544 1.8666-2.6622 2.4572-1.1079 0.5904-2.4363 0.8856-3.9852 0.8856-1.5273 0-2.8825-0.2905-4.0658-0.8713-1.1832-0.581-2.1082-1.4144-2.775-2.5-0.667-1.0856-1.0004-2.3809-1.0004-3.8857 0-1.5618 0.3873-2.8902 1.1616-3.9855 0.7746-1.0952 1.7695-1.9285 2.985-2.4999 1.2153-0.5714 2.49-0.8571 3.8238-0.8571zm-0.0969 0.5713c-0.6238 0-1.1563 0.1668-1.5973 0.5001-0.4409 0.3333-0.7852 0.9237-1.0326 1.7714-0.2474 0.8475-0.3818 2.0427-0.4033 3.5856h5.5179c0.2152-2.0762 0.1291-3.5714-0.2582-4.4857-0.3872-0.9142-1.1294-1.3714-2.2265-1.3714z"
                                    fill="#333" />
                                <path
                                    d="m58.882 44.704c-0.8388 0-1.6079-0.1428-2.3071-0.4285-0.6991-0.2858-1.2477-0.7476-1.6457-1.3857s-0.5862-1.4809-0.5647-2.5285l0.0969-7.3426c0-0.4381-0.0861-0.7523-0.2582-0.9429-0.1721-0.1904-0.4411-0.3237-0.8067-0.4l-0.3873-0.1142v-0.2858l5.7761-0.9999 0.3226 0.2858-0.1612 4.0569v6.8856c0 0.6095 0.1989 1.057 0.5968 1.3428 0.3981 0.2856 0.8982 0.4285 1.5006 0.4285 0.6023 0 1.1402-0.0762 1.6134-0.2285 0.4733-0.1524 0.9465-0.381 1.4197-0.6858l0.1292-9.314c0-0.4381-0.0753-0.7476-0.2259-0.9284-0.1506-0.181-0.4303-0.3096-0.839-0.3857l-0.3226-0.0858v-0.2857l5.6469-1.0857 0.3226 0.2858-0.0967 4.0569v7.7426c0 0.4191 0.0645 0.7429 0.1936 0.9715 0.129 0.2285 0.4087 0.4189 0.839 0.5714l0.3227 0.0856v0.2858l-5.647 0.2857-0.2904-1.6856c-0.71 0.5142-1.479 0.9475-2.3073 1.2998-0.8282 0.3525-1.8015 0.5286-2.9203 0.5286z"
                                    fill="#333" />
                                <path
                                    d="m71.351 44.274v-0.2858l0.4518-0.1142c0.4087-0.0952 0.6938-0.2572 0.8551-0.4858 0.1613-0.2285 0.2527-0.5333 0.2743-0.9142v-8.9425c0-0.4191-0.0753-0.7286-0.2259-0.9286s-0.4409-0.3381-0.8713-0.4143l-0.484-0.1143v-0.2857l5.5179-1.657 0.3227 0.2856 0.2582 1.9428c0.7098-0.6285 1.5219-1.1618 2.4362-1.5999 0.9144-0.4381 1.8662-0.6571 2.8557-0.6571 1.0327 0 1.8609 0.1857 2.4848 0.5571 0.6238 0.3714 1.097 0.9475 1.4198 1.7285 0.839-0.7047 1.7478-1.2619 2.7266-1.6713 0.9788-0.4096 1.9307-0.6143 2.8558-0.6143 1.5273 0 2.6514 0.3238 3.3721 0.9713 0.7207 0.6477 1.081 1.6667 1.081 3.0571v8.3712c0 0.7429 0.3871 1.2095 1.1616 1.3999l0.3549 0.0857v0.2858h-7.454v-0.2858l0.3228-0.0857c0.4087-0.1143 0.6937-0.2857 0.855-0.5143 0.1614-0.2285 0.242-0.5333 0.242-0.9142v-8.7426c0-0.7999-0.1612-1.3475-0.484-1.6427-0.3226-0.2954-0.8389-0.443-1.5489-0.443-0.5593 0-1.1025 0.1097-1.6295 0.3286-0.5271 0.219-1.081 0.5476-1.6618 0.9857 0.1075 0.3048 0.1775 0.6381 0.2097 1 0.0323 0.3619 0.0484 0.7429 0.0484 1.1429v7.3997c0.0215 0.7429 0.4087 1.2095 1.1617 1.3999l0.2903 0.0857v0.2858h-7.4862v-0.2858l0.4518-0.1142c0.4087-0.1144 0.6937-0.2809 0.8551-0.5001 0.1614-0.2189 0.242-0.519 0.242-0.8999v-8.714c0-0.7619-0.1559-1.3047-0.4679-1.6285-0.3119-0.3239-0.8443-0.4858-1.5972-0.4858-0.4948 0-0.9896 0.1097-1.4844 0.3286-0.4948 0.219-1.0111 0.5381-1.5489 0.9572v9.5711c0 0.7429 0.3764 1.2095 1.1293 1.3999l0.2906 0.0857v0.2858h-7.5832z"
                                    fill="#333" />
                                <path
                                    d="m103.96 44.702c-1.247 0-2.296-0.3143-3.146-0.9428-0.8496-0.6286-1.2746-1.5333-1.2746-2.7142 0-0.8953 0.5166-1.719 1.5486-2.4714 1.033-0.7523 2.657-1.319 4.873-1.6999 0.344-0.0572 0.731-0.119 1.162-0.1857 0.43-0.0666 0.882-0.1381 1.355-0.2142v-2.2572c0-1.2952-0.178-2.2046-0.533-2.7284-0.355-0.5239-0.909-0.7857-1.661-0.7857-0.517 0-0.925 0.1476-1.227 0.4428-0.301 0.2952-0.494 0.8047-0.581 1.5286l-0.064 0.3142c-0.043 0.8191-0.269 1.4191-0.678 1.7999-0.408 0.381-0.914 0.5715-1.516 0.5715-0.56 0-1.022-0.1524-1.388-0.4571-0.365-0.3048-0.548-0.7143-0.548-1.2285 0-0.781 0.306-1.4381 0.919-1.9714s1.425-0.9333 2.437-1.2c1.011-0.2666 2.108-0.4 3.291-0.4 1.914 0 3.394 0.4143 4.437 1.2428 1.043 0.8286 1.565 2.1761 1.565 4.0427v6.3141c0 0.8571 0.452 1.2856 1.355 1.2856h0.71l0.29 0.2286c-0.408 0.4572-0.855 0.8095-1.339 1.0572-0.484 0.2475-1.167 0.3714-2.049 0.3714-0.946 0-1.694-0.1953-2.242-0.5857-0.549-0.3905-0.909-0.9096-1.081-1.5571-0.646 0.6095-1.307 1.1286-1.985 1.5571-0.677 0.4285-1.554 0.6428-2.63 0.6428zm1.969-1.7428c0.43 0 0.833-0.0905 1.21-0.2714 0.376-0.181 0.823-0.4619 1.339-0.8428v-4.7999c-0.215 0.0381-0.431 0.0762-0.646 0.1143s-0.462 0.0762-0.742 0.1143c-0.989 0.2286-1.78 0.6143-2.372 1.157-0.591 0.5429-0.887 1.2715-0.887 2.1857 0 0.781 0.205 1.3667 0.613 1.7571 0.409 0.3905 0.904 0.5857 1.485 0.5857z"
                                    fill="#333" />
                                <path d="m114.94 15.938h-8.986v8.0038h8.986v-8.0038z" fill="#0FF0C0" />
                                <path d="M114.939 0H105.953V7.97447H114.939V0Z" fill="#0FF0C0" />
                                <path d="m123.83 24.616h3.036v5.7692h-8.986v-14.448h5.95v8.6784z" clip-rule="evenodd"
                                    fill="#0FAFF0" fill-rule="evenodd" />
                                <path d="m126.87 5.2454h-8.986v2.734h8.986v-2.734z" fill="#0FAFF0" />
                                <path d="m105.96 15.926h-2.886v-7.9565h2.886v7.9565z" clip-rule="evenodd" fill="#0FAFF0"
                                    fill-rule="evenodd" />
                                <path d="m117.88 15.926h-2.941v-7.9565h2.941v7.9565z" clip-rule="evenodd" fill="#0FAFF0"
                                    fill-rule="evenodd" />
                                <path d="m126.87 7.969h9.067v2.8156h-9.067v-2.8156z" clip-rule="evenodd" fill="#0FAFF0"
                                    fill-rule="evenodd" />
                                <path d="m114.94 7.969v7.9565h-8.986v-7.9565h8.986z" clip-rule="evenodd" fill="#01A5B5"
                                    fill-rule="evenodd" />
                                <path d="m126.87 7.969v2.8156h-3.036v5.1409h-5.95v-7.9565h8.986z" clip-rule="evenodd"
                                    fill="#0178E2" fill-rule="evenodd" />
                                <path d="m138.93 10.794v13.819h-12.064v-8.6784h9.067v-5.1409h2.997z" clip-rule="evenodd"
                                    fill="#0FF0C0" fill-rule="evenodd" />
                                <path d="m126.88 15.938h-3.036v8.6784h3.036v-8.6784z" fill="#01A5B5" />
                                <path d="m135.93 10.794h-9.067v5.1409h9.067v-5.1409z" fill="#01A5B5" />
                                <path d="m126.88 10.794h-3.036v5.1409h3.036v-5.1409z" fill="#0071AA" />
                            </svg></a>
                        </div>
                        <div className="hide-smallup">
                            <div id="menuToggle">
                                <input type="checkbox" />
                                <span></span>
                                <span></span>

                                <ul className="none" id="menu">
                                    <li><a href={PageRoutes.PATIENTS}>For Patients</a></li>
                                    <li><a href={PageRoutes.HEALTH_INSTITUTIONS}>For Health Institutions</a></li>
                                    <li><a href={PageRoutes.GOVERNMENT_ENTERPRISES}>For Government & Enterprises</a></li>
                                    <li><a href={PageRoutes.ABOUT}>About Us</a></li>
                                    {/* <li>
                                        <button className="slideshow-single2-btn" onClick={() => window.open('https://patients.pneuma.care/', '_self')}>
                                            Login as a patient
                                        </button>
                                    </li> */}

                                    <li className="mobile-social-li">
                                        <a href=""><svg width="22px" height="16px" viewBox="0 0 31 26" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M27.8259 6.47218C27.8393 6.75389 27.8444 7.03906 27.8444 7.32077C27.8444 15.9979 21.4473 26 9.74984 26C6.1562 26 2.81527 24.9132 0 23.0491C1.6652 23.2569 3.35376 23.124 4.96916 22.658C6.58457 22.1919 8.09515 21.4019 9.41456 20.3329C8.08766 20.307 6.80176 19.8538 5.73665 19.0367C4.67154 18.2195 3.88046 17.0792 3.47402 15.7753C4.42771 15.9594 5.40923 15.9207 6.34658 15.6623C4.90712 15.361 3.61285 14.5553 2.68309 13.3818C1.75334 12.2083 1.2453 10.739 1.24505 9.22312V9.14139C2.12821 9.64845 3.11595 9.93044 4.12603 9.96389C2.77799 9.03633 1.82374 7.61276 1.45804 5.98373C1.09234 4.35471 1.34277 2.64305 2.15821 1.19811C3.75502 3.22646 5.74739 4.88544 8.00588 6.06729C10.2644 7.24913 12.7385 7.92741 15.2675 8.05806C15.1581 7.56702 15.1033 7.06472 15.1041 6.56087C15.1041 5.69914 15.2686 4.84585 15.5881 4.04973C15.9077 3.25362 16.376 2.53028 16.9665 1.92103C17.557 1.31177 18.2579 0.828537 19.0293 0.498925C19.8008 0.169314 20.6275 -0.000219675 21.4624 8.71859e-06C22.3326 -0.00145588 23.1937 0.181619 23.9924 0.537877C24.7912 0.894135 25.5105 1.41598 26.1057 2.07104C27.5296 1.78011 28.8952 1.2415 30.1441 0.478206C29.6691 1.99836 28.6752 3.28865 27.3474 4.10902C28.608 3.95477 29.8392 3.60661 31 3.07612C30.1473 4.39496 29.0722 5.5452 27.8259 6.47218Z"
                                                fill="#E5E5E5" />
                                        </svg></a>
                                        <a href=""><svg width="8px" height="23px" viewBox="0 0 15 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M9.74454 30V16.3379H14.187L14.8474 10.9887H9.74454V7.58147C9.74454 6.0379 10.1591 4.98107 12.2943 4.98107H15V0.211982C13.6835 0.0656107 12.3603 -0.00506416 11.0363 0.000281988C7.10958 0.000281988 4.41356 2.48734 4.41356 7.05306V10.9787H0V16.3278H4.4232V30H9.74454Z"
                                                fill="#E5E5E5" />
                                        </svg></a>
                                        <a href=""><svg width="18px" height="20px" viewBox="0 0 28 30" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.524 1.03296e-06C3.06146 -0.000357238 2.60338 0.092485 2.17593 0.273225C1.74848 0.453965 1.36004 0.71906 1.03279 1.05337C0.705537 1.38768 0.445892 1.78465 0.268683 2.22161C0.0914744 2.65857 0.000175006 3.12695 0 3.6C0 5.59091 1.57733 7.24091 3.52 7.24091C5.46533 7.24091 7.044 5.59091 7.044 3.60273C7.04435 3.12974 6.95356 2.66132 6.77682 2.22423C6.60008 1.78714 6.34086 1.38996 6.01396 1.05538C5.68706 0.720801 5.2989 0.455383 4.87166 0.274295C4.44442 0.0932077 3.98648 8.9723e-07 3.524 1.03296e-06ZM20.7133 9.54546C17.7547 9.54546 16.0627 11.1273 15.2493 12.7009H15.164V9.96955H9.33333V30H15.4093V20.085C15.4093 17.4723 15.6013 14.9468 18.7627 14.9468C21.8787 14.9468 21.924 17.9264 21.924 20.25V30H28V18.9982C28 13.6145 26.868 9.54546 20.7133 9.54546ZM0.484 9.96818V30H6.564V9.96818H0.484Z"
                                                fill="#E5E5E5" />
                                        </svg></a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>
                    <div className="show-smallup">
                        <ul className="none inline-list mb-0">
                            <li><a href={PageRoutes.PATIENTS} className={`alt-link ${window.location.pathname === PageRoutes.PATIENTS && 'active'}`}>For Patients</a></li>
                            <li><a href={PageRoutes.HEALTH_INSTITUTIONS} className={`alt-link ${location.pathname === PageRoutes.HEALTH_INSTITUTIONS && 'active'}`}>For Health Institutions</a></li>
                            <li><a href={PageRoutes.GOVERNMENT_ENTERPRISES} className={`alt-link ${location.pathname === PageRoutes.GOVERNMENT_ENTERPRISES && 'active'}`}>For Government & Enterprises</a></li>
                            <li><a href={PageRoutes.ABOUT} className={`alt-link ${window.location.pathname === PageRoutes.ABOUT && 'active'}`}>About Us</a></li>
                            {/* <li>
                                <button className="slideshow-single2-btn" onClick={() => window.open('https://patients.pneuma.care/', '_self')}>
                                    Login as a patient
                                </button>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
            <header>
            </header>
        </>
    )
}
