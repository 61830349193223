import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import patientsIntro from '../uploads/patient-page-1.jpg'
import founder from '../uploads/founder.png'
import fig11 from '../uploads/patient-page-2.jpg'
import fig10 from '../uploads/patient-page-3.jpg'
import fig9 from '../uploads/patient-page-4.jpg'
import fig8 from '../uploads/fig-8.jpg'
import fig7 from '../uploads/fig-7.jpg'
import fig6 from '../uploads/fig-6.jpg'

export default function Patients() {

    useEffect(() => {
        document.title = 'PneumaCare | For Patients'
    }, [])

    const toggleAccordion = e => {
        e.preventDefault();
        const anchorTitle = e.target;
        const accordionBody = document.getElementById(`accord-${e.target.id}`);
        if (accordionBody.classList.contains('open')) {
            anchorTitle.classList.remove('title-open');   
            accordionBody.style.height = 0;
            accordionBody.classList.remove('open')
        } else {
            anchorTitle.classList.add('title-open');
            accordionBody.classList.add('open')
            accordionBody.style.height = `${accordionBody.scrollHeight}px`;
        }
    }

    return (
        <>
            <a className="sr" href="#main">Skip to content</a>
            <h1 className="sr">For Patients</h1>
            <main className="" id="">
                <Header />
                <section className="about-container patients-page">
                    <div className="about-container-1">
                        <p className="home-sections-title">
                            FOR PATIENTS
                        </p>
                        <h2 className="home-section-h2">
                            Better healthcare <br /> All in one place for you
                        </h2>
                        <img className="about-image" src={patientsIntro}
                            alt="three-patients-side-by-side-with-one-male-doctor-above" />

                        <div className="team-container with-icons">
                            <h2 className="home-section-h2">
                                Healthcare redesigned to meet <br /> your needs in a timely manner
                            </h2>
                            <div className="d-flx j-c-sb icons">
                                <div>
                                    <img src={fig11} />
                                    <p>Skip the huddles to accessing quality care in a few clicks</p>
                                </div>
                                <div>
                                    <img src={fig10} />
                                    <p>Grow your practice and business by deploying digital capabilities for your patients</p>
                                </div>
                                <div>
                                    <img src={fig9} />
                                    <p>Manage all of your data, care seeking and experience in one place</p>
                                </div>
                            </div>
                            {/* <button className="hero-text-btn black-variant" onClick={() => window.open('https://patients.pneuma.care/', '_blank')}>
                                Create an account
                                <span><svg width="20" height="21" viewBox="0 0 36 21" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M24.4323 0.440585C24.5716 0.300925 24.7371 0.19012 24.9192 0.114518C25.1014 0.0389147 25.2968 0 25.494 0C25.6913 0 25.8866 0.0389147 26.0688 0.114518C26.251 0.19012 26.4165 0.300925 26.5558 0.440585L35.5538 9.43862C35.6935 9.57793 35.8043 9.74342 35.8799 9.92562C35.9555 10.1078 35.9944 10.3031 35.9944 10.5004C35.9944 10.6977 35.9555 10.893 35.8799 11.0752C35.8043 11.2574 35.6935 11.4229 35.5538 11.5622L26.5558 20.5602C26.2742 20.8418 25.8923 21 25.494 21C25.0958 21 24.7139 20.8418 24.4323 20.5602C24.1507 20.2786 23.9925 19.8967 23.9925 19.4984C23.9925 19.1002 24.1507 18.7183 24.4323 18.4367L32.3715 10.5004L24.4323 2.56412C24.2926 2.42482 24.1818 2.25933 24.1062 2.07713C24.0306 1.89493 23.9917 1.69961 23.9917 1.50235C23.9917 1.30509 24.0306 1.10977 24.1062 0.927578C24.1818 0.745382 24.2926 0.579891 24.4323 0.440585Z"
                                        fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0 10.5006C0 10.1029 0.158001 9.72146 0.439244 9.44022C0.720488 9.15898 1.10194 9.00098 1.49967 9.00098H32.9928C33.3905 9.00098 33.772 9.15898 34.0532 9.44022C34.3345 9.72146 34.4925 10.1029 34.4925 10.5006C34.4925 10.8984 34.3345 11.2798 34.0532 11.5611C33.772 11.8423 33.3905 12.0003 32.9928 12.0003H1.49967C1.10194 12.0003 0.720488 11.8423 0.439244 11.5611C0.158001 11.2798 0 10.8984 0 10.5006Z"
                                        fill="white" />
                                </svg></span>
                            </button> */}
                        </div>

                        <div className="team-container treatment">
                            <h2 className="home-section-h2 mb-1">
                                What we treat via <br /> telemedicine
                            </h2>
                            <div className="d-flx j-c-sb">
                                <div>
                                    <ul>
                                        <li>Abdominal Pain</li>
                                        <li>Allergies</li>
                                        <li>Anal Itching</li>
                                        <li>Antenatal advice</li>
                                        <li>Asthma</li>
                                        <li>Breastfeeding issues</li>
                                        <li>Chronic pain</li>
                                        <li>Cold, Flu & Respiratory Tract Infections</li>
                                        <li>Conjunctivitis</li>
                                        <li>Constipation</li>
                                        <li>Contraceptive advice</li>
                                        <li>Diarrheal diseases (mild symptoms)</li>
                                        <li>Erectile dysfunction</li>
                                        <li>Food Poisoning</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li>Genital discharge</li>
                                        <li>Genital bleeding outside of pregnancy</li>
                                        <li>Gout</li>
                                        <li>Headaches and Migraines</li>
                                        <li>Heavy periods</li>
                                        <li>Indigestion</li>
                                        <li>Insect Bites</li>
                                        <li>Intestinal parasites</li>
                                        <li>Joints pain</li>
                                        <li>Menopause issues</li>
                                        <li>Mild depression</li>
                                        <li>Mild injuries</li>
                                        <li>Mild pain</li>
                                        <li>Morning sickness</li>
                                        <li>Nutrition advice</li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li>Painful periods</li>
                                        <li>Painful urination</li>
                                        <li>Palliative care</li>
                                        <li>Postnatal advice</li>
                                        <li>Prescription refill for chronic conditions</li>
                                        <li>Sexually Transmitted Infections</li>
                                        <li>Simple flu</li>
                                        <li>Simple Malaria and prophylaxis</li>
                                        <li>Skin conditions</li>
                                        <li>Sleeping disorder</li>
                                        <li>Stomach ache and acid reflux disease</li>
                                        <li>Stress and anxiety</li>
                                        <li>Tonsillitis</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>


                <section className="pricing-container faq-section">
                    <div className="pricing-container1">
                        <h2 className="home-section-h2 faq-h2">
                            Frequently Asked Questions
                        </h2>
                    </div>
                    <div className="">
                        <ul className="faq-ul" uk-accordion>
                            <li className="faq-li">
                                <a className="uk-accordion-title faq-a" id="1" href="" onClick={toggleAccordion}>
                                    Is PneumaCare free ?
                                </a>
                                <div className="uk-accordion-content faq-content-container" id="accord-1">
                                    <p>
                                        Owning a PneumaCare account and accessing some level of care is free for every patient on the platform.
                                    </p>
                                </div>
                            </li>
                            <li className="faq-li">
                                <a className="uk-accordion-title faq-a" id="2" href="" onClick={toggleAccordion}>
                                    How long will it take me before a doctor respond to my chat?
                                </a>
                                <div className="uk-accordion-content faq-content-container" id="accord-2">
                                    <p>A doctor will typically respond in two minutes or less during peak hours(8:00am - 8:00pm).</p>
                                </div>
                            </li>
                            <li className="faq-li">
                                <a className="uk-accordion-title faq-a" id="3" href="" onClick={toggleAccordion}>
                                    Are all the service providers verified?
                                </a>
                                <div className="uk-accordion-content faq-content-container" id="accord-3">
                                    <p>Yes, all service providers are verified and on-boarded by our provider management team.</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>

                <Footer />
            </main >

        </>
    )
}
