import React, { useState, useEffect } from 'react'
import { PageRoutes } from '../components/constants/routes';
import Footer from '../components/Footer'
import Header from '../components/Header'
import heroIntro from '../uploads/hero-img.png'
import signature from '../uploads/founder-signature.svg'
import pneumaPass from '../uploads/pneumapass.svg'
import partner1 from '../uploads/partner-1.png'
import partner2 from '../uploads/partner-2.png'
import partner3 from '../uploads/partner-3-eha.png'
import partner4 from '../uploads/partner-4-pngfirmcare.png'
import partner5 from '../uploads/partner-5-pngwellahealth.svg'
import partner6 from '../uploads/mdaasglobal.png'
import hannah from '../uploads/hannah.png'
import ronald from '../uploads/ronald.png'
import micheal from '../uploads/micheal.png'
import doctor from '../uploads/doctor.png'
import stethoscope from '../uploads/stethoscope.png'



export default function Home() {
    const [slideIndex, setSlideIndex] = useState(1);

    return (
        <>
            <a className="sr" href="#main">Skip to content</a>
            <h1 className="sr">Pneuma</h1>
            <main className="" id="">
                <Header />
                {/* <section> */}
                <section className="hero-container">
                    <div className="hero-text-container">
                        <h1 className="hero-text-h1">
                            Improved health outcomes for all
                        </h1>
                        <p className="hero-text-p">
                            Our technology and infrastructure
                            enables patients and providers to access
                            and deliver improved healthcare outcomes
                            and experiences.
                        </p>
                        {/* <a href="http://patients.pneuma.care/" className="hero-text-btn">
                            For patients
                            <span><svg width="20" height="21" viewBox="0 0 36 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M24.4323 0.440585C24.5716 0.300925 24.7371 0.19012 24.9192 0.114518C25.1014 0.0389147 25.2968 0 25.494 0C25.6913 0 25.8866 0.0389147 26.0688 0.114518C26.251 0.19012 26.4165 0.300925 26.5558 0.440585L35.5538 9.43862C35.6935 9.57793 35.8043 9.74342 35.8799 9.92562C35.9555 10.1078 35.9944 10.3031 35.9944 10.5004C35.9944 10.6977 35.9555 10.893 35.8799 11.0752C35.8043 11.2574 35.6935 11.4229 35.5538 11.5622L26.5558 20.5602C26.2742 20.8418 25.8923 21 25.494 21C25.0958 21 24.7139 20.8418 24.4323 20.5602C24.1507 20.2786 23.9925 19.8967 23.9925 19.4984C23.9925 19.1002 24.1507 18.7183 24.4323 18.4367L32.3715 10.5004L24.4323 2.56412C24.2926 2.42482 24.1818 2.25933 24.1062 2.07713C24.0306 1.89493 23.9917 1.69961 23.9917 1.50235C23.9917 1.30509 24.0306 1.10977 24.1062 0.927578C24.1818 0.745382 24.2926 0.579891 24.4323 0.440585Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 10.5006C0 10.1029 0.158001 9.72146 0.439244 9.44022C0.720488 9.15898 1.10194 9.00098 1.49967 9.00098H32.9928C33.3905 9.00098 33.772 9.15898 34.0532 9.44022C34.3345 9.72146 34.4925 10.1029 34.4925 10.5006C34.4925 10.8984 34.3345 11.2798 34.0532 11.5611C33.772 11.8423 33.3905 12.0003 32.9928 12.0003H1.49967C1.10194 12.0003 0.720488 11.8423 0.439244 11.5611C0.158001 11.2798 0 10.8984 0 10.5006Z"
                                    fill="white" />
                            </svg></span>
                        </a> */}
                        <a href={PageRoutes.HEALTH_INSTITUTIONS} className="hero-text-btn">
                            For providers
                            <span><svg width="20" height="21" viewBox="0 0 36 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M24.4323 0.440585C24.5716 0.300925 24.7371 0.19012 24.9192 0.114518C25.1014 0.0389147 25.2968 0 25.494 0C25.6913 0 25.8866 0.0389147 26.0688 0.114518C26.251 0.19012 26.4165 0.300925 26.5558 0.440585L35.5538 9.43862C35.6935 9.57793 35.8043 9.74342 35.8799 9.92562C35.9555 10.1078 35.9944 10.3031 35.9944 10.5004C35.9944 10.6977 35.9555 10.893 35.8799 11.0752C35.8043 11.2574 35.6935 11.4229 35.5538 11.5622L26.5558 20.5602C26.2742 20.8418 25.8923 21 25.494 21C25.0958 21 24.7139 20.8418 24.4323 20.5602C24.1507 20.2786 23.9925 19.8967 23.9925 19.4984C23.9925 19.1002 24.1507 18.7183 24.4323 18.4367L32.3715 10.5004L24.4323 2.56412C24.2926 2.42482 24.1818 2.25933 24.1062 2.07713C24.0306 1.89493 23.9917 1.69961 23.9917 1.50235C23.9917 1.30509 24.0306 1.10977 24.1062 0.927578C24.1818 0.745382 24.2926 0.579891 24.4323 0.440585Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 10.5006C0 10.1029 0.158001 9.72146 0.439244 9.44022C0.720488 9.15898 1.10194 9.00098 1.49967 9.00098H32.9928C33.3905 9.00098 33.772 9.15898 34.0532 9.44022C34.3345 9.72146 34.4925 10.1029 34.4925 10.5006C34.4925 10.8984 34.3345 11.2798 34.0532 11.5611C33.772 11.8423 33.3905 12.0003 32.9928 12.0003H1.49967C1.10194 12.0003 0.720488 11.8423 0.439244 11.5611C0.158001 11.2798 0 10.8984 0 10.5006Z"
                                    fill="white" />
                            </svg></span>
                        </a>
                    </div>
                    <div className="hero-img-container">
                        <img src={heroIntro} alt="Image of patients with masks on." />
                    </div>
                </section>
                <section className="about-preview-container">
                    <div className="about-preview-container2">
                        <div className="about-preview-box-container">
                            <p className="home-sections-title">
                                ABOUT US
                            </p>
                            <h2 className="home-section-h2 mb-3">
                                We are making
                                healthcare possible
                                for everyone
                            </h2>
                            <a href={PageRoutes.ABOUT} className="about-preview-box-btn">
                                Learn More About Us
                            </a>
                        </div>
                        <div className="about-preview-text-container">
                            <p className="about-preview-text-p1">
                                Empowering everyone, everywhere to be healthy by transforming how healthcare is delivered through technology.
                            </p>
                            <p className="about-preview-text-p2">
                                We are on a mission of enabling quality healthcare for 300M africans by 2030.
                            </p>
                            <p className="about-preview-text-p3 mb-0">
                                Ayodele Adeyemo
                            </p>
                            <p className="about-preview-text-p4">
                                Founder & CEO
                            </p>
                            <img src={signature} alt="" />
                        </div>
                    </div>
                </section>

                <section className="products-container">
                    <p className="home-sections-title mb-0">
                        OUR PRODUCTS
                    </p>
                    <div className="product-container2">
                        <h2 className="product-container2-h2 home-section-h2">
                            Learn how <br /> Pneuma works
                        </h2>
                        <ul className="none products-list mb-0">
                            <li className={`product-list-item ${slideIndex === 1 && 'active'}`} onClick={() => setSlideIndex(1)}>
                                For patients
                            </li>
                            <li className={`product-list-item ${slideIndex === 2 && 'active'}`} onClick={() => setSlideIndex(2)}>
                                Patient Engagement
                            </li>
                            <li className={`product-list-item ${slideIndex === 3 && 'active'}`} onClick={() => setSlideIndex(3)}>
                                Page
                            </li>
                            <li className={`product-list-item ${slideIndex === 4 && 'active'}`} onClick={() => setSlideIndex(4)}>
                                Developer Tools
                            </li>
                        </ul>
                    </div>
                    <div>
                        <div className="slideshow-single" style={{ display: slideIndex === 1 ? 'block' : 'none' }}>
                            <h3 className="slideshow-single-h3">
                                For patients
                            </h3>
                            <div className="slideshow-single2">
                                <div className="slideshow-single2-text">
                                    <h2 className="slideshow-single2-h2">
                                        All your healthcare in one place
                                    </h2>
                                    <p className="slideshow-single2-p">
                                        The PneumaCare platform empowers patients to take control of their health by bringing everything they need to be healthy and get healthcare in one single place.
                                    </p>
                                    {/* <p className="slideshow-single2-p2">
                                        Create an account to claim unlimited free doctor consults and up to 20% discount on your bills
                                    </p>
                                    <button className="slideshow-single2-btn" onClick={() => window.open('https://patients.pneuma.care/', '_blank')}>
                                        Sign up
                                    </button> */}
                                </div>
                                <div>
                                    <img src={pneumaPass} alt="an image for Pneumo pass product" />
                                </div>
                            </div>
                        </div>
                        <div className="slideshow-single" style={{ display: slideIndex === 2 ? 'block' : 'none' }}>
                            <h3 className="slideshow-single-h3">
                                Patient Engagement
                            </h3>
                            <div className="slideshow-single2">
                                <div className="slideshow-single2-text expe">
                                    <h2 className="slideshow-single2-h2">
                                        Improved care experience and outcomes
                                    </h2>
                                    <p className="slideshow-single2-p">
                                        Our patient engagement platform enables private providers and small clinics to better manage their patients and their encounter data all in one place.
                                    </p>
                                    <p className="slideshow-single2-p2">
                                        Request access to the patient engagement platform.
                                    </p>
                                    <button className="slideshow-single2-btn"
                                        onClick={() => window.open('https://pneuma.typeform.com/to/nQBYZxPX/share#/embed?mode=popup', '_blank')} type="button">
                                        Request access
                                    </button>
                                </div>
                                <div>
                                    <img src={pneumaPass} alt="an image for Pneumo pass product" />
                                </div>
                            </div>
                        </div>
                        <div className="slideshow-single" style={{ display: slideIndex === 3 ? 'block' : 'none' }}>
                            <h3 className="slideshow-single-h3">
                                Page
                            </h3>
                            <div className="slideshow-single2">
                                <div className="slideshow-single2-text">
                                    <h2 className="slideshow-single2-h2">
                                        Get paid and convert customers faster.
                                    </h2>
                                    <p className="slideshow-single2-p">
                                        Our link-in-bio website and one click booking/checkout service allows healthcare services and businesses to launch and grow their business from social media platforms by accepting appointments and payment faster.
                                    </p>
                                    <p className="slideshow-single2-p2">
                                        See a demo service page here.
                                    </p>
                                    <button className="slideshow-single2-btn" onClick={() => window.open('https://pneuma.typeform.com/to/Wg4Ucpk9/share#/embed?mode=popup', '_blank')}>
                                        Get started
                                    </button>
                                </div>
                                <div>
                                    <img src={pneumaPass} alt="an image for Pneumo pass product" />
                                </div>
                            </div>
                        </div>
                        <div className="slideshow-single" style={{ display: slideIndex === 4 ? 'block' : 'none' }}>
                            <h3 className="slideshow-single-h3">
                                Developer Tools
                            </h3>
                            <div className="slideshow-single2">
                                <div className="slideshow-single2-text">
                                    <h2 className="slideshow-single2-h2">
                                        Build great tele-health products.
                                    </h2>
                                    <p className="slideshow-single2-p">
                                        Our API platform provides you the fastest and cheapest to launch a digital health product or integrate into a third party service or platform.
                                    </p>
                                    <p className="slideshow-single2-p2">
                                        Learn how it works from our documentation website.
                                    </p>
                                    <button className="slideshow-single2-btn" onClick={() => window.location.href = 'http://pneumacare.readme.io/'}> Start building </button>
                                </div>
                                <div>
                                    <img src={pneumaPass} alt="an image for Pneumo pass product" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="partners-container">
                    <div className="partners-container2">
                        <p className="home-sections-title mb-0">
                            OUR PARTNERS
                        </p>
                        <h2 className="home-section-h2">
                            A robust service <br /> ecosystem built for you
                        </h2>
                        <div className="partners-logo-container">
                            <img src={partner1} width="210" height="66" alt="MEDICAID" />
                            <img src={partner2} width="210" height="66" alt="SYNLAB" />
                            <img src={partner3} width="210" height="66" alt="EHACLINICS" />
                            <img src={partner4} width="210" height="66" alt="FIRMCARE" />
                            <img src={partner5} width="210" height="66" alt="wellahealth" />
                            <img src={partner6} width="210" height="66" alt="mdaasglobal" />


                            {/* </section> */}

                        </div>
                        <div>
                            <div className="press-container">
                                <p className="home-sections-title mb-0">
                                    TESTIMONIALS & PRESS
                                </p>
                                <h2 className="home-section-h2">
                                    What People say <br /> about us
                                </h2>
                            </div>
                            <div className="press-grid-container"
                                uk-scrollspy="cls: uk-animation-fade; target: .press-grid-item; delay: 600; repeat: true">
                                <div className="press-grid-item">
                                    <p className="press-review">
                                        “After my consults with the doctor and my lab bookings, I received my test results. It was a seamless process.”
                                    </p>
                                    <div className="d-flx j-c-sb al-i-c">
                                        <div>
                                            <p className="mb-0 press-name">
                                                Zee
                                            </p>
                                            <p className="mb-0 press-location">
                                                Abuja, Nigeria
                                            </p>
                                        </div>
                                        <img className="press-img" src={hannah} alt="Hannah avatar image" />
                                    </div>
                                </div>
                                <div className="press-grid-item">
                                    <p className="press-review">
                                        “Delivering telehealth with PneumaHealth has been great. Faster response times and scalable widgets.”
                                    </p>
                                    <div className="d-flx j-c-sb al-i-c">
                                        <div>
                                            <p className="mb-0 press-name">
                                                Greg - <a href="https://wellahealth.com" target="_blank">WellaHealth</a>
                                            </p>
                                            <p className="mb-0 press-location">
                                                Abuja, Nigeria
                                            </p>
                                        </div>
                                        <img className="press-img" src={ronald} alt="Ronald avatar image" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section id="blog" className="blog-container">
                    <p className="home-sections-title mb-0">
                        OUR BLOG
                    </p>
                    <h2 className="home-section-h2">
                        What we write on the internet
                    </h2>
                    <div className="blog-grid-container"
                        uk-scrollspy="cls: uk-animation-fade; target: .blog-grid-item; delay: 600; repeat: true">

                        <div className="blog-grid-item d-flx j-c-sb">
                            <div className="blog-grid-item-text">
                                <div className="d-flx al-i-c j-c-sb blog-name-avatar">
                                    <img src={micheal} alt="An avatar image of micheal" />
                                    <div>
                                        <p className="mb-0 blog-p">Micheal Abori</p>
                                        <p className="mb-0 blog-blurred-p">Sales Rep</p>
                                    </div>
                                </div>
                                <p className="blog-p mt-1h">
                                    Health is everything, learn how pneuma is transforming the country.
                                </p>
                                <p className="mb-0 blog-blurred-p mt-2h">
                                    HEALTH - PNEUMA
                                </p>
                            </div>
                            <img className="blog-img" src={doctor}
                                alt="A picture with the doctor wearing a white coat" />
                        </div>

                        <div className="blog-grid-item d-flx j-c-sb">
                            <div className="blog-grid-item-text">
                                <div className="d-flx al-i-c j-c-sb blog-name-avatar">
                                    <img src={micheal} alt="An avatar image of micheal" />
                                    <div>
                                        <p className="mb-0 blog-p">Micheal Abori</p>
                                        <p className="mb-0 blog-blurred-p">Sales Rep</p>
                                    </div>
                                </div>
                                <p className="blog-p mt-1h">
                                    Access to good quality health services is a basic human right.
                                </p>
                                <p className="mb-0 blog-blurred-p mt-2h">
                                    HEALTH - PNEUMA
                                </p>
                            </div>
                            <img className="blog-img" src={stethoscope} alt="A picture of a stethoscope" />
                        </div>
                    </div>

                </section>
                {/* here */}
                <Footer />
            </main>

        </>
    )
}
