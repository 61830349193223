import React, { useEffect } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import twoWomen from '../uploads/two-women.png'

export default function About() {
    useEffect(() => {
      document.title = 'PneumaCare | About Us'
    }, [])


    return ( 
        <>
            <a className="sr" href="#main">Skip to content</a>
            <h1 className="sr">About Us</h1>
            <main className="" id="">
                <Header />
                <section className="about-container">
                    <div className="about-container-1">
                        <p className="home-sections-title">
                            ABOUT US
                        </p>
                        <h2 className="home-section-h2">
                            Healthcare in Africa is <br /> broken and we are fixing it
                        </h2>
                        <img className="about-image" src={twoWomen}  
                            alt="two-women-having-a-meeting-inside-glass-panel-office" />
                        <p className="about-p">
                            We are a healthcare company on a mission to making sure quality healthcare is accessible to everyone
                            in Africa. We are building technologies, services and infrastructures that would power healthcare
                            delivery and access for patients and providers.<br />
                            Healthcare delivery is becoming harder every year due to a declining healthcare infrastructure,
                            broken service ecosystem, exodus of healthcare providers and a rise of healthcare privatization
                            which is increasing the cost of care. <br />
                            We believe that digital health will drive access to healthcare for everyone and we are positioning
                            our technology to accelerate digital health development and scale on the continent.

                        </p>
                        <button className="hero-text-btn black-variant" onClick={()=> window.location.href = '/'}> 
                            Get started
                            <span><svg width="20" height="21" viewBox="0 0 36 21" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M24.4323 0.440585C24.5716 0.300925 24.7371 0.19012 24.9192 0.114518C25.1014 0.0389147 25.2968 0 25.494 0C25.6913 0 25.8866 0.0389147 26.0688 0.114518C26.251 0.19012 26.4165 0.300925 26.5558 0.440585L35.5538 9.43862C35.6935 9.57793 35.8043 9.74342 35.8799 9.92562C35.9555 10.1078 35.9944 10.3031 35.9944 10.5004C35.9944 10.6977 35.9555 10.893 35.8799 11.0752C35.8043 11.2574 35.6935 11.4229 35.5538 11.5622L26.5558 20.5602C26.2742 20.8418 25.8923 21 25.494 21C25.0958 21 24.7139 20.8418 24.4323 20.5602C24.1507 20.2786 23.9925 19.8967 23.9925 19.4984C23.9925 19.1002 24.1507 18.7183 24.4323 18.4367L32.3715 10.5004L24.4323 2.56412C24.2926 2.42482 24.1818 2.25933 24.1062 2.07713C24.0306 1.89493 23.9917 1.69961 23.9917 1.50235C23.9917 1.30509 24.0306 1.10977 24.1062 0.927578C24.1818 0.745382 24.2926 0.579891 24.4323 0.440585Z"
                                    fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 10.5006C0 10.1029 0.158001 9.72146 0.439244 9.44022C0.720488 9.15898 1.10194 9.00098 1.49967 9.00098H32.9928C33.3905 9.00098 33.772 9.15898 34.0532 9.44022C34.3345 9.72146 34.4925 10.1029 34.4925 10.5006C34.4925 10.8984 34.3345 11.2798 34.0532 11.5611C33.772 11.8423 33.3905 12.0003 32.9928 12.0003H1.49967C1.10194 12.0003 0.720488 11.8423 0.439244 11.5611C0.158001 11.2798 0 10.8984 0 10.5006Z"
                                    fill="white" />
                            </svg></span>
                        </button>

                    </div>

                    <div className="team-container">
                        <p className="home-sections-title">
                            OUR MISSION
                        </p>
                        <h2 className="home-section-h2">
                            Where we are going
                        </h2>
                        <p className="about-p">
                            PneumaHealth’s mission is to make quality and affordable healthcare possible for everyone in
                            Africa.
                        </p>
                    </div>

                    <div className="team-container">
                        <p className="home-sections-title">
                            OUR VISION
                        </p>
                        <h2 className="home-section-h2">
                            What we see there
                        </h2>
                        <p className="about-p">
                            Our vision is to build a suite of tools, products and APIs that enables healthcare delivery and access for providers and patients.
                        </p>
                    </div>
                </section>
                <Footer />
            </main>

        </>
    )
}
