import React, { useEffect } from 'react'

export default function Footer() {

    return (
        <footer className="footer-container">
            <div className="footer-container2">
                <div className="footer-icons d-flx j-c-sb">
                    <svg viewBox="0 0 154 56" width="140px" height="45px"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="m0 55.099v-0.365l0.72979-0.2432c0.48653-0.1216 0.72979-0.3649 0.97306-0.6082 0.12163-0.2432 0.24326-0.6081 0.24326-1.0947v-18.61c0-0.4865-0.12163-0.8514-0.24326-1.0947-0.12164-0.2433-0.48653-0.4865-0.97306-0.7298l-0.72979-0.2433v-0.3649h8.6358c3.5274 0 6.0816 0.6082 7.6628 1.9462 1.5812 1.3379 2.311 3.0407 2.311 5.1085 0 1.3379-0.3649 2.5542-0.9731 3.7706-0.6082 1.0947-1.7028 2.0677-3.1624 2.6759-1.4596 0.6081-3.5273 0.973-6.0816 0.973h-1.2163v6.5681c0 0.9731 0.48653 1.5812 1.338 1.8245l1.0947 0.3649v0.3649h-9.6089v-0.2432zm7.2979-9.7306h1.338c1.2163 0 2.0678-0.2432 2.7976-0.6081s1.3379-1.0947 1.5812-1.9462c0.3649-0.973 0.4865-2.311 0.4865-3.8922 0-1.7028-0.2432-3.0408-0.6081-4.0138-0.3649-0.9731-0.9731-1.5812-1.7029-1.9461s-1.7028-0.6082-2.7975-0.6082h-1.2163v13.015h0.12163z"
                            fill="#fff" />
                        <path
                            d="m19.948 55.099v-0.3649l0.4865-0.1216c0.8515-0.2433 1.2163-0.8514 1.2163-1.7029v-11.19c0-0.4865-0.1216-0.8514-0.2432-1.0947-0.1217-0.2432-0.4865-0.3649-0.9731-0.4865l-0.4865-0.1216v-0.3649l6.0816-2.0678 0.3649 0.3649 0.2432 2.1894c0.8515-0.7298 1.8245-1.3379 2.7976-1.8245 1.0947-0.4865 2.0677-0.7298 3.0408-0.7298 1.5812 0 2.7975 0.4866 3.6489 1.338s1.2163 2.1894 1.2163 3.8922v10.217c0 0.973 0.4866 1.4595 1.338 1.7028l0.3649 0.1216v0.3649h-8.3926v-0.3649l0.4865-0.1216c0.8514-0.2433 1.2163-0.8514 1.2163-1.7028v-11.555c0-1.4596-0.7297-2.311-2.311-2.311-1.0946 0-2.1893 0.4865-3.4057 1.5812v12.042c0 0.973 0.3649 1.4596 1.2164 1.7029l0.3649 0.1216v0.3649h-8.271v0.1216z"
                            fill="#fff" />
                        <path
                            d="m49.382 37.463c1.5812 0 2.7975 0.3649 3.8922 0.9731 1.0947 0.6081 1.9461 1.4596 2.5543 2.5543 0.6081 1.0946 0.8514 2.311 0.8514 3.6489v0.8514c0 0.2433-0.1216 0.4865-0.1216 0.7298h-10.582c0 2.5543 0.4865 4.3788 1.338 5.4734 0.8514 1.0947 2.1893 1.7029 4.0138 1.7029 1.2163 0 2.1894-0.2433 2.9192-0.6082s1.4595-0.973 2.0677-1.7028l0.3649 0.3649c-0.7298 1.3379-1.7028 2.311-2.9192 3.0408-1.2163 0.7298-2.6759 1.0947-4.3787 1.0947-1.7029 0-3.1624-0.3649-4.5004-1.0947-1.3379-0.7298-2.311-1.7029-3.0408-3.1624-0.7298-1.338-1.0947-2.9192-1.0947-4.8653s0.4866-3.649 1.338-4.9869c0.8514-1.338 1.9461-2.4327 3.2841-3.1625 1.3379-0.7297 2.5542-0.8514 4.0138-0.8514zm-0.1216 0.7298c-0.7298 0-1.2164 0.2433-1.7029 0.6082s-0.8514 1.0947-1.0947 2.1894c-0.2432 1.0946-0.3649 2.5542-0.4865 4.5003h6.0816c0.2433-2.5543 0.1216-4.5003-0.2433-5.595-0.4865-1.2163-1.3379-1.7029-2.5542-1.7029z"
                            fill="#fff" />
                        <path
                            d="m64.951 55.586c-0.973 0-1.8244-0.1216-2.5542-0.4865s-1.338-0.9731-1.8245-1.7029c-0.4865-0.8514-0.6082-1.8245-0.6082-3.1624l0.1217-9.1224c0-0.4865-0.1217-0.973-0.2433-1.2163s-0.4865-0.3649-0.8514-0.4866l-0.4865-0.1216v-0.3649l6.3248-1.2163 0.3649 0.3649-0.1216 5.1085v8.5143c0 0.7298 0.2432 1.3379 0.6081 1.7028 0.4866 0.3649 0.9731 0.4866 1.7029 0.4866 0.6081 0 1.2163-0.1217 1.8244-0.2433 0.4866-0.2433 1.0947-0.4866 1.5813-0.8515l0.1216-11.555c0-0.4865-0.1216-0.973-0.2433-1.2163-0.1216-0.2433-0.4865-0.3649-0.973-0.4865l-0.3649-0.1217v-0.3648l6.2032-1.338 0.3649 0.3649-0.1216 5.1085v9.6089c0 0.4866 0.1216 0.9731 0.2432 1.2164 0.1217 0.2432 0.4865 0.4865 0.9731 0.7298l0.3649 0.1216v0.3649l-6.2032 0.3649-0.3649-2.0678c-0.7298 0.6082-1.5813 1.2164-2.5543 1.5813-0.9731 0.3649-2.0677 0.4865-3.2841 0.4865z"
                            fill="#fff" />
                        <path
                            d="m78.696 55.099v-0.3649l0.4866-0.1216c0.4865-0.1217 0.7298-0.3649 0.973-0.6082 0.1216-0.2433 0.2433-0.6081 0.2433-1.0947v-11.19c0-0.4865-0.1217-0.8514-0.2433-1.0947-0.1216-0.2432-0.4865-0.3649-0.973-0.4865l-0.4866-0.1216v-0.3649l6.0816-2.0678 0.3649 0.3649 0.2433 2.4327c0.7298-0.7298 1.7028-1.4596 2.6759-1.9461 0.973-0.4866 2.0677-0.8515 3.1624-0.8515s2.0677 0.2433 2.7975 0.7298c0.7298 0.4866 1.2163 1.2164 1.5812 2.1894 0.9731-0.8514 1.9462-1.5812 3.0408-2.0677 1.0947-0.4866 2.0677-0.7298 3.1627-0.7298 1.703 0 2.919 0.3649 3.77 1.2163 0.852 0.8514 1.217 2.0677 1.217 3.7706v10.46c0 0.9731 0.486 1.4596 1.338 1.7028l0.365 0.1217v0.3649h-8.271v-0.3649l0.364-0.1217c0.487-0.1216 0.73-0.3648 0.974-0.6081 0.243-0.2433 0.243-0.6082 0.243-1.0947v-10.825c0-0.9731-0.122-1.7029-0.487-2.0678s-0.973-0.6081-1.7026-0.6081c-0.6082 0-1.2163 0.1216-1.8245 0.3649-0.6081 0.2432-1.2163 0.7298-1.8245 1.2163 0.1217 0.3649 0.2433 0.8514 0.2433 1.2163v1.4596 9.244c0 0.9731 0.4865 1.4596 1.338 1.7028l0.3649 0.1217v0.3649h-8.271v-0.3649l0.4865-0.1217c0.4865-0.1216 0.7298-0.3648 0.9731-0.6081 0.1216-0.2433 0.2432-0.6082 0.2432-1.0947v-10.825c0-0.9731-0.1216-1.5812-0.4865-2.0678-0.3649-0.3649-0.9731-0.6081-1.7028-0.6081-0.4866 0-1.0947 0.1216-1.5812 0.3649-0.4866 0.2432-1.0947 0.7298-1.7029 1.2163v11.92c0 0.9731 0.3649 1.4596 1.2163 1.7028l0.3649 0.1217v0.3649h-8.7575v-0.2433z"
                            fill="#fff" />
                        <path
                            d="m114.7 55.586c-1.338 0-2.555-0.3649-3.528-1.2163-0.973-0.7298-1.459-1.9462-1.459-3.4058 0-1.0946 0.608-2.1893 1.703-3.0408 1.094-0.973 2.919-1.7028 5.351-2.0677 0.365-0.1216 0.852-0.1216 1.338-0.2432 0.487-0.1217 0.973-0.1217 1.46-0.2433v-2.7975c0-1.5813-0.243-2.7976-0.608-3.4057-0.365-0.6082-0.973-0.9731-1.825-0.9731-0.608 0-0.973 0.2433-1.338 0.6082-0.364 0.3649-0.486 0.973-0.608 1.9461l-0.121 0.3649c0 0.9731-0.244 1.8245-0.73 2.1894-0.487 0.4865-0.973 0.7298-1.703 0.7298-0.608 0-1.095-0.2433-1.581-0.6082-0.365-0.3649-0.608-0.8515-0.608-1.5812 0-0.9731 0.365-1.8245 0.973-2.4327 0.729-0.6081 1.581-1.2163 2.676-1.4595 1.094-0.3649 2.311-0.4866 3.649-0.4866 2.067 0 3.77 0.4866 4.865 1.5812 1.094 0.9731 1.703 2.6759 1.703 4.987v7.906c0 1.0947 0.486 1.5812 1.459 1.5812h0.73l0.365 0.2433c-0.487 0.6082-0.973 0.9731-1.46 1.3379-0.486 0.3649-1.338 0.4866-2.311 0.4866-1.094 0-1.824-0.2433-2.432-0.7298-0.608-0.4866-0.973-1.0947-1.217-1.9461-0.729 0.7297-1.459 1.4595-2.189 1.9461-0.487 0.4865-1.46 0.7298-2.554 0.7298zm2.189-2.0678c0.487 0 0.973-0.1216 1.338-0.3649 0.365-0.2432 0.851-0.6081 1.46-1.0946v-5.96c-0.244 0-0.487 0.1216-0.73 0.1216s-0.487 0.1216-0.852 0.1216c-1.094 0.2433-1.946 0.7299-2.675 1.4596-0.609 0.7298-0.974 1.5812-0.974 2.6759 0 0.9731 0.244 1.7029 0.73 2.1894 0.487 0.6082 0.973 0.8514 1.703 0.8514z"
                            fill="#fff" />
                        <path d="m126.74 0h-9.852v29.8h9.852v-29.8z" fill="#fff" opacity=".8" />
                        <path d="m139.88 6.4465h-9.853v31.259h9.853v-31.259z" fill="#fff" opacity=".8" />
                        <path d="m153.26 13.379h-17.028v17.028h17.028v-17.028z" fill="#fff" opacity=".8" />
                        <path d="m150.2 19.655v-9.8522h-36.49v9.8522h36.49z" fill="#fff" opacity=".8" />
                    </svg>
                    <ul className="none footer-social">
                        <li>
                            <a href="https://twitter.com/Pneumahealth" target="_blank"><svg width="22px" height="16px" viewBox="0 0 31 26" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M27.8259 6.47218C27.8393 6.75389 27.8444 7.03906 27.8444 7.32077C27.8444 15.9979 21.4473 26 9.74984 26C6.1562 26 2.81527 24.9132 0 23.0491C1.6652 23.2569 3.35376 23.124 4.96916 22.658C6.58457 22.1919 8.09515 21.4019 9.41456 20.3329C8.08766 20.307 6.80176 19.8538 5.73665 19.0367C4.67154 18.2195 3.88046 17.0792 3.47402 15.7753C4.42771 15.9594 5.40923 15.9207 6.34658 15.6623C4.90712 15.361 3.61285 14.5553 2.68309 13.3818C1.75334 12.2083 1.2453 10.739 1.24505 9.22312V9.14139C2.12821 9.64845 3.11595 9.93044 4.12603 9.96389C2.77799 9.03633 1.82374 7.61276 1.45804 5.98373C1.09234 4.35471 1.34277 2.64305 2.15821 1.19811C3.75502 3.22646 5.74739 4.88544 8.00588 6.06729C10.2644 7.24913 12.7385 7.92741 15.2675 8.05806C15.1581 7.56702 15.1033 7.06472 15.1041 6.56087C15.1041 5.69914 15.2686 4.84585 15.5881 4.04973C15.9077 3.25362 16.376 2.53028 16.9665 1.92103C17.557 1.31177 18.2579 0.828537 19.0293 0.498925C19.8008 0.169314 20.6275 -0.000219675 21.4624 8.71859e-06C22.3326 -0.00145588 23.1937 0.181619 23.9924 0.537877C24.7912 0.894135 25.5105 1.41598 26.1057 2.07104C27.5296 1.78011 28.8952 1.2415 30.1441 0.478206C29.6691 1.99836 28.6752 3.28865 27.3474 4.10902C28.608 3.95477 29.8392 3.60661 31 3.07612C30.1473 4.39496 29.0722 5.5452 27.8259 6.47218Z"
                                    fill="#E5E5E5" />
                            </svg></a>
                        </li>
                        <li>
                            <a href="http://www.facebook.com/mypneumacare" target="_blank"><svg width="8px" height="23px" viewBox="0 0 15 30" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.74454 30V16.3379H14.187L14.8474 10.9887H9.74454V7.58147C9.74454 6.0379 10.1591 4.98107 12.2943 4.98107H15V0.211982C13.6835 0.0656107 12.3603 -0.00506416 11.0363 0.000281988C7.10958 0.000281988 4.41356 2.48734 4.41356 7.05306V10.9787H0V16.3278H4.4232V30H9.74454Z"
                                    fill="#E5E5E5" />
                            </svg></a>
                        </li>
                        <li>
                            <a href=""><svg width="18px" height="20px" viewBox="0 0 28 30" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.524 1.03296e-06C3.06146 -0.000357238 2.60338 0.092485 2.17593 0.273225C1.74848 0.453965 1.36004 0.71906 1.03279 1.05337C0.705537 1.38768 0.445892 1.78465 0.268683 2.22161C0.0914744 2.65857 0.000175006 3.12695 0 3.6C0 5.59091 1.57733 7.24091 3.52 7.24091C5.46533 7.24091 7.044 5.59091 7.044 3.60273C7.04435 3.12974 6.95356 2.66132 6.77682 2.22423C6.60008 1.78714 6.34086 1.38996 6.01396 1.05538C5.68706 0.720801 5.2989 0.455383 4.87166 0.274295C4.44442 0.0932077 3.98648 8.9723e-07 3.524 1.03296e-06ZM20.7133 9.54546C17.7547 9.54546 16.0627 11.1273 15.2493 12.7009H15.164V9.96955H9.33333V30H15.4093V20.085C15.4093 17.4723 15.6013 14.9468 18.7627 14.9468C21.8787 14.9468 21.924 17.9264 21.924 20.25V30H28V18.9982C28 13.6145 26.868 9.54546 20.7133 9.54546ZM0.484 9.96818V30H6.564V9.96818H0.484Z"
                                    fill="#E5E5E5" />
                            </svg></a>
                        </li>
                    </ul>
                </div>
                <div className="d-flx j-c-sb footer-text-links">
                    <div>
                        <p className="footer-p">Products</p>
                        <ul className="none">
                            <li className="footer-li">
                                <a href="http://pneuma.care/" target="_blank">
                                    PneumaCare For Patients
                                </a>
                            </li>
                            <li className="footer-li">
                                <a href="http://livewellhealth.care/" target="_blank">
                                    LiveWell
                                </a>
                            </li>
                            <li className="footer-li">
                                <a href="http://pneumacare.readme.io/" target="_blank">
                                    API Documentation
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="footer-p">
                            Company
                        </p>
                        <ul className="none footer-ul">
                            <li className="footer-li">
                                <a href="https://docs.google.com/document/d/1bMV8P8jrYK4CEhzycsizXCwFFgVQR5RR/edit?usp=sharing&ouid=105868615193864824009&rtpof=true&sd=true" target="_blank">
                                    Data Service Agreement
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="item-e">
                        <ul className="none">
                            <li className="footer-li">
                                <a style={{ marginRight: '10px' }} href="tel:+2348117790867">+2348117790867</a>
                                <a href="tel:+2348154827399">+2348154827399</a>
                            </li>
                            <li className="footer-li">
                                <a href="#">
                                    hello@pneuma.care
                                </a>
                            </li>
                            <li className="footer-li">
                                <a href="#">   
                                    No 50 Ebitu Ekiwe Street, Jabi Abuja.
                                </a>
                            </li>
                        </ul>
                        <p className="footer-copyright-p">
                            Copyright © PneumaCare Ltd {new Date().getFullYear()}. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
