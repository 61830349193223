
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./screens/About";
import GovernmentEnterprise from "./screens/GovernmentEnterprise";
import HealthInstitue from "./screens/HealthInstitue";
import Home from "./screens/Home";
import './sass/style.sass'    
import { PageRoutes } from "./components/constants/routes";
import Patients from "./screens/Patients";


const Error404Page = () => {
  return (
    <div style={{ margin: '10px auto', maxWidth: '600px', textAlign: 'center' }}>
      <h1 style={{ margin: '30px 0', fontSize: '4em', lineHeight: 1, letterSpacing: '-1px' }}>404</h1>
      <p><strong>Page not found :(</strong></p>
      <p>The requested page could not be found.</p>
    </div>
  )
}

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path={PageRoutes.HOME} exact element={<Home/>} />
        <Route path={PageRoutes.ABOUT} exact element={<About/>} />
        <Route path={PageRoutes.GOVERNMENT_ENTERPRISES} exact element={<GovernmentEnterprise/>} />
        <Route path={PageRoutes.HEALTH_INSTITUTIONS} exact element={<HealthInstitue/>} />
        <Route path={PageRoutes.PATIENTS} exact element={<Patients/>} />
        <Route path='*' exact element={<Error404Page/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;